<template>
  <div class="p-wrap">
    <!-- 功能区 -->
    <div class="p-header">
      <!--      <div class="header-title">-->
      <!--        <div class="title-left">查询时间：{{ handleStatisticalTimeFormat(this.queryTime) }}</div>-->
      <!--      </div>-->
      <div class="header-select">
        <el-form class="form" ref="differenceDetailList" :model="filter">
          <el-row>
            <el-col :span="6">
              <el-form-item label="购方名称">
                <el-input v-model="filter.buyerName" placeholder="请输入购方名称" @keyup.enter.native="handleQuery()"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="中类编码">
                <el-input v-model="filter.middleCoding" placeholder="请输入中类编码" @keyup.enter.native="handleQuery()"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="中类名称">
                <el-input v-model="filter.middleName" placeholder="请输入中类名称"
                          @keyup.enter.native="handleQuery()"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-button type="default" @click="handleReset">重置</el-button>
              <el-button type="primary" @click="handleQuery()">查询</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <!-- 内容区 -->
    <div class="p-content">
      <div class="main-top">
        <div class="top-left">
          <el-radio v-model="displaySign" label="5">{{ handleStatisticalTimeFormat(this.filter.statisticsMonth) }}</el-radio>
          <el-radio v-model="displaySign" label="1">总金额</el-radio>
          <el-radio v-model="displaySign" label="2">开票金额</el-radio>
          <el-radio v-model="displaySign" label="3">差异金额</el-radio>
        </div>
        <div class="exportBtn">
          <el-button type="primary" @click="handleExportTableExcel">导出</el-button>
        </div>
      </div>
      <div class="p-table">
        <el-table :data="records" border stripe v-loading="tableLoading" ref="customerInfoTable"
                  @selection-change="handleSelection"
                  :header-cell-style="handleHeaderCellStyle">
          <el-table-column type="selection" width="50" fixed="left"/>
          <el-table-column prop="sellerName" label="销方名称"></el-table-column>
          <el-table-column prop="buyerName" label="购方名称"></el-table-column>
          <el-table-column prop="middleCoding" label="中类编码" min-width="140"></el-table-column>
          <el-table-column prop="middleName" label="中类名称"></el-table-column>
          <el-table-column v-if="displaySign == 1 || displaySign == 5" prop="totalSumAmount"
                           label="总价税合计（元）"></el-table-column>
          <el-table-column v-if="displaySign == 1 || displaySign == 5" prop="totalAmount"
                           label="总金额（元）"></el-table-column>
          <el-table-column v-if="displaySign == 1 || displaySign == 5" prop="totalTaxAmount"
                           label="总税额（元）"></el-table-column>
          <el-table-column v-if="displaySign == 1 || displaySign == 5" prop="totalQuantity"
                           label="总数量"></el-table-column>
          <el-table-column v-if="displaySign == 2 || displaySign == 5" prop="openSumAmount"
                           label="开票价税合计（元）"></el-table-column>
          <el-table-column v-if="displaySign == 2 || displaySign == 5" prop="openAmount"
                           label="开票金额（元）"></el-table-column>
          <el-table-column v-if="displaySign == 2 || displaySign == 5" prop="openTaxAmount"
                           label="开票税额（元）"></el-table-column>
          <el-table-column v-if="displaySign == 2 || displaySign == 5" prop="openQuantity"
                           label="开票数量"></el-table-column>
          <el-table-column v-if="displaySign == 3 || displaySign == 5" prop="notOpenSumAmount"
                           label="价税合计差异（元）"></el-table-column>
          <el-table-column v-if="displaySign == 3 || displaySign == 5" prop="notOpenAmount"
                           label="差异金额（元）"></el-table-column>
          <el-table-column v-if="displaySign == 3 || displaySign == 5" prop="notOpenTaxAmount"
                           label="差异税额（元）"></el-table-column>
          <el-table-column v-if="displaySign == 3 || displaySign == 5" prop="notOpenQuantity"
                           label="差异数量"></el-table-column>
        </el-table>
      </div>
      <div v-if="records.length > 0" class="p-page">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleQuery"
                       :current-page.sync="filter.page"
                       :page-sizes="[15, 30, 50, 100]"
                       :page-size="filter.size"
                       layout="total, sizes, prev, pager, next"
                       :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {tableStyle, dateOption} from '@/util/mixins'
import {statisticsCondition, statisticsDownload} from '@/service/statistical-analysis.js'

export default {
  name: 'SalKpcy',
  components: {},
  mixins: [tableStyle, dateOption],
  data() {
    return {
      // 筛选条件
      filter: {
        orgId: 0,
        buyerName: '',
        middleCoding: '',
        middleName: '',
        statisticsMonth: '',
        page: 1,
        size: 15
      },
      tableLoading: false,             // 表格加载loading
      records: [],
      selections: [],           //选择行
      total: 0,                // 数据总数count
      displaySign: '5'
    }
  },
  created() {
    this.filter.statisticsMonth = this.$route.query.statisticsMonth
    this.handleQuery()
  },
  methods: {
    // 重置表单
    handleReset() {
      this.filter.buyerName = ''
      this.filter.middleCoding = ''
      this.filter.middleName = ''
    },
    // 查询
    async handleQuery(val) {
      this.filter.page = 1
      if (val) {
        this.filter.page = val
      }

      this.filter.orgId = 119
      this.tableLoading = true
      const {success, data} = await statisticsCondition(this.filter)
      this.tableLoading = false
      if (success) {
        this.records = data.records
        this.total = data.total
      }
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.filter.size = val
      this.handleQuery()
    },
    async handleExportTableExcel() {
      if (this.selections.length == 0) {
        this.toast('请勾选需要导出的差异信息', 'warning')
        return
      }
      let items = this.selections
      this.exportLoading = true
      let ids = items.map(item => item.id)
      let param = {
        ids: ids,
        displaySign: this.displaySign
      }
      const rsp = await statisticsDownload(param)
      this.exportLoading = false
      this.downloadFile(rsp)
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value
    },
    handleStatisticalTimeFormat(date) {
      return this.$moment(date).format("YYYY年MM月")
    }
  }
}
</script>

<style scoped lang="scss">
.p-header {
  .header-title {
    display: flex;
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;

    .title-right {
      display: inline-flex;

      span {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;

        img {
          width: 18px;
          height: 18px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }

      span:hover {
        cursor: pointer;
      }
    }
  }
}

::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        margin-left: 10px;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
  }

}

.part {
  width: 100%;
  height: 1px;
  background: #E5E5E5;
  margin: 15px 0;
}

.dialog-wrap {
  display: flex;
  height: 160px;
  flex-direction: column;

  .dialog-select {
    margin-bottom: 24px;

    label {
      width: 80px;
      text-align: left;
      display: inline-block;
    }

    label:before {
      content: "*";
      color: #F5222D;
      margin-right: 2px;
    }

    i {
      left: 140px;
      display: block;
      color: #F5222D;
      margin-top: 4px;
      position: absolute;
    }
  }

  .dialog-button {
    margin-top: 20px;
    margin-left: 110px
  }

  .backImg {
    bottom: 0;
    width: 100%;
    opacity: 0.5;
    height: 44px;
    position: absolute;
  }
}
</style>
